.parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

.swiper-slide .title {
    margin-left: 25px;
    font-size: 41px;
    font-weight: 300;
    color: #86c33a;
}

.swiper-slide .subtitle {
    font-size: 21px;
    margin-left: 25px;
    color: #86c33a;
}

.swiper-slide .text {
    font-size: 14px;
    max-width: 400px;
    line-height: 1.3;
    margin-left: 25px;
    color: #86c33a;
}