.swiper {
  /* width: 300px; */
  height: 500px;
  /* position: absolute; */
  /* left: 50%;
  top: 50%; */
  /* margin-left: -150px;
  margin-top: -150px; */
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-pagination-bullet {
  background-color: #86c33a;
  /* Change to your desired color */
  width: 10px;
  height: 10px;
  margin: 0 5px;
}